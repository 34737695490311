.footer-container {
  background-color: #e9f0fc; /* Dark blue background */
  color: #171616; /* White text color */
  display: grid;
  grid-template-columns: 25% 1fr; /* 25% for logo, rest for content */
  padding: 50px 20px;
  align-items: start;
  gap: 20px;
}

.footer-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-logo img {
  width: 90px; /* Reduced size for the logo */
  padding: 10px; /* Add some space around the logo */
  /* border: 2px solid #131212; White border around the logo */
  /* border-radius: 10px; Rounded corners for the border */
  /* filter: brightness(0) invert(1); Makes the logo white */
}

.footer-content {
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 20px;
}

.footer-acknowledgement h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #1c1a1a;
}

.footer-acknowledgement p {
  font-size: 1rem;
  line-height: 1.6;
  color: #111111; /* Softer white for text */
}

.footer-links {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns for links */
  gap: 20px;
}

.footer-links .column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.footer-links a {
  color: #13277e; /* Vibrant cyan for the links */
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #ffffff; /* White on hover */
}

@media (max-width: 768px) {
  .footer-container {
    grid-template-columns: 1fr; /* Stack everything on mobile */
    text-align: center;
  }

  .footer-links {
    grid-template-columns: 1fr; /* One column for links on small screens */
  }

  .footer-logo {
    justify-content: center;
    margin-bottom: 20px;
  }
}
