@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

@media (max-width: 768px) {
    .chatbot-window {
        width: 90%; /* Responsive on smaller devices */
        height: 80%; /* Adjust height accordingly */
        max-width: 100%; /* Ensure it fits within the viewport */
        min-width: unset; /* Remove the minimum width constraint for smaller screens */
    }
    .chatbot-container {
        bottom: 10px; /* Move it a bit higher from the bottom */
        right: 10px;  /* Make it closer to the edge */
    }
    .chatbot-header h3 {
        font-size: 20px; /* Reduce the font size for mobile */
    }

    .chatbot-header img {
        width: 40px;
        height: 40px;
    }

    .chatbot-body {
        height: auto; /* Allow body to expand with content on mobile */
    }

    .chatbot-icon img {
        width: 120px;
        height: 120px;
    }

    .chatbot-input {
        font-size: 14px; /* Adjust input font size */
    }

    .chatbot-form button {
        padding: 8px 16px;
        font-size: 14px;
    }

    .bot-message, .user-message {
        max-width: 90%; /* Reduce message width for better readability on mobile */
        font-size: 14px; /* Adjust font size for messages */
    }

    .chatbot-form {
        flex-direction: column; /* Stack input and button vertically on smaller screens */
    }

    .chatbot-input, .chatbot-form button {
        width: 100%; /* Make the input and button full width */
        margin-bottom: 10px;
    }
}

.chatbot-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1100;
    max-width: 100%;
    max-height: 100%;
}

.chatbot-icon img {
    width: 165px;
    height: 165px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
}

.chatbot-window {
    width: 400px;
    max-width: 90%;
    min-width: 300px;
    height: 600px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgb(0 0 0 / 20%);
    display: flex;
    flex-direction: column;
    opacity: 100;
    transition: opacity 0.3s ease, transform 0.3s ease;
    overflow-y: auto;
}

.chatbot-header {
    background-color: #006adb;
    color: white;
    padding: 10px;
    border-radius: 10px 10px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.chatbot-header img {
    width: 60px;
    height: 60px;
    border-radius: 50px;
    background-color: #fff;
}

.chatbot-header h3 {
    flex-grow: 1;
    text-align: center;
    margin: 0;
    font-size: 30px;
    font-weight: bold;
    font-family: Roboto, sans-serif;
}

.chatbot-header button {
    background: none;
    border: none;
    color: white;
    font-size: 18px;
    cursor: pointer;
}

.chat-message {
    padding: 10px;
    margin: 10px 0;
    border-radius: 10px;
    max-width: 80%;
}

.bot-message-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.bot-icon-outside {
    width: 40px;
    height: 40px;
    margin-right: 5px;
    border-radius: 50%;
    object-fit: cover;
}

.bot-message {
    background-color: #e6e6e6;
    color: black;
    padding: 15px;
    border-radius: 10px 10px 10px 0;
    max-width: 80%;
}

.user-message-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.user-message {
    background-color: #c7c7c7;
    color: black;
    padding: 15px;
    border-radius: 10px 10px 0;
    max-width: 80%;
}

.predefined-questions-list {
    list-style-type: disc;
    padding-left: 20px;
    margin: 0;
}

.predefined-question {
    cursor: pointer;
    padding: 5px 0;
    transition: color 0.3s ease, padding-left 0.3s ease;
}

.predefined-question:hover {
    background-color: #e6f2ff;
    color: #0056b3;
    cursor: pointer;
    text-decoration: underline;
    padding-left: 5px;
}

.chatbot-body {
    display: flex;
    flex-direction: column;
    height: 80%;
    overflow-y: auto;
    padding: 10px;
    border-top: 1px solid #ddd;
    scrollbar-width: thin;
    scrollbar-color: #007bff #f1f1f1;
}

.chatbot-body::-webkit-scrollbar {
    width: 6px;
}

.chatbot-body::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.chatbot-body::-webkit-scrollbar-thumb {
    background-color: #0056b3;
    border-radius: 10px;
}

.chatbot-form {
    display: flex;
    align-items: center;
    padding: 10px;
    border-top: 1px solid #ddd;
    background-color: #f9f9f9;
    gap: 0;
}

.chatbot-input {
    width: 90%;
    padding: 10px;
    margin: 0 auto;
    border: 1px solid #ddd;
    border-radius: 5px;
    outline: none;
    max-height: 80px;
    overflow-y: auto;
    resize: none;
}

.chatbot-form button {
    padding: 10px 20px;
    background-color: #006adb;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.chatbot-form button:hover {
    background-color: #003d80;
}

.voice-button {
    padding: 10px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.3s ease;
}

.voice-button:hover {
    background-color: #218838;
}

