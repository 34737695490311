/* Font imports */

/* @import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chivo:ital,wght@0,100..900;1,100..900&display=swap'); */

.chivo-header {
    font-family: Chivo, sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
}

.bebas-neue-regular {
    font-family: "Bebas Neue", sans-serif;
    font-weight: 700; /* Bold weight */
    font-style: normal; /* Should be normal, italic, or oblique */
}

.inter-header {
    font-family: Inter, sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
}
  
.inter-body-text {
    font-family: Inter, sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

.job-description-input {
    width: 60%; /* Full width */
    resize: none; /* Disable resizing if needed */
    margin-top: auto; /* Push the textarea to the bottom */
    align-items: center;
}
/* Job description header section (Full width) */
.job-description-header-section {
    width: 100%; 
    background-color: #fff; 
    padding: 20px 0;
    margin: 0; 
    display: flex;
    justify-content: center; 
    align-items: center;
}

/* Job analysis container */
.job-analysis-container {
    max-width: 1300px; 
    width: 90%;
    margin: 30px 0 auto;
    padding: 20px;
    background-size: 90%;
    background-blend-mode: normal;
    
    border-radius: 10px;
    /*box-shadow: 0 10px 16px rgb(0 0 0 / 10%);  */
    /* font-family: Arial, sans-serif;  */
    text-align: left;
}

.job-analysis-container2 {
    max-width: 1300px; 
    width: 90%;
    margin: 300px 0 auto;
    padding: 20px;
    background-size: 90%;
    background-blend-mode: normal;
    border-radius: 10px;
    box-shadow: 0 10px 16px rgb(0 0 0 / 10%);
    font-family: Arial, sans-serif; 
    text-align: left;
}

.job-analysis-header {
    background-color: #6c8cb298;
    padding: 20px;
    border-radius: 10px; 
    margin-bottom: 45px;
}

/* h2 {
    font-size: 40px;
    font-weight: bold;
    color: #2e3f52; 
    text-align: center;
    margin-bottom: 20px;
} */

.job-analysis-header-h4 {
    font-size: 15px;
    font-weight: bold;
    color: #4d4f52; 
    text-align: center;
    margin-bottom: 20px;
}

/* Form styling */
form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.input-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
}

/* Textarea styling */
textarea {
    width: 60%;
    height: 120px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #dcdcdc;
    font-size: 16px;
    outline: none;
    resize: none;
    margin-right: 15px;
}

textarea::placeholder {
    color: #a5a5a5;
}

/* Spacing between elements */
.span-text {
    font-size: 16px;
    color: #0056b3;
    font-weight: bold;
    margin: 0 15px;
    margin-top: 10px;
}

.file-info {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

/* Remove button */
.remove-button {
    background-color: #ca362c;
    color: white;
    border: none;
    padding: 5px 10px;
    margin-left: 10px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 0.8rem;
}

.remove-button:hover {
    background-color: #9e2222;
}

.file-info span {
    margin-right: 8px;
    color: green;
    font-size: 20px;
}

/* File upload styling */
.file-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 15px;
}

.file-upload input {
    display: none;
}

.file-upload label {
    background-color: #0056b3;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
}

.file-upload label::after {
    content: '\1F4C4'; /* Unicode for the document icon emoji */
    font-size: 20px;
    margin-left: 8px;
}

/* Button styling */
button {
    background-color: #0056b3;
    color: white;
    padding: 10px 20px;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 15px;
}

button:hover {
    background-color: #35356e;
}

/* Analysis result */
.analysis-result {
    max-width: 1300px; 
    width: 90%;
    margin-top: 30px;
    background-color: #E9ECF6;
    padding: 15px;
    border-radius: 8px;
    text-align: left;
    font-family: 'Montserrat', sans-serif;
}

.analysis-result h3 {
    font-size: 18px;
    color: #4A4A8C;
    font-weight: bold;
    margin-bottom: 10px;
}

.analysis-result p {
    font-size: 16px;
    line-height: 1.5;
    color: #333;
}

h4 {
    font-size: 18px;
    color: #0056b3;
    font-weight: bold;
    margin-top: 10px;
}
.warning-message {
    margin-top: 20px;
    padding: 10px;
    width: 90%;
    background-color: #f9fcfa; /* Light red or pink background */
    border: 1px solid #f3f4f8; /* Red border */
    border-radius: 20px;
    font-family: 'Montserrat', sans-serif;
  }
  
  .warning-message p {
    margin: 0;
    color: #120101 !important; 
    font-size: 1rem!important;
  }
/* Red flags section */
.red-flags {
    margin-top: 15px;
}

.link-style {
    color: #00438a; /* Adjust the color to match your design */
    text-decoration: underline; /* Optional: Adds an underline to the link */
  }
  
  .link-style:hover {
    text-decoration: none; /* Optional: Remove underline on hover */
    color: #002a5d; /* Optional: Change color on hover */
  }

.red-flags ul {
    list-style-type: disc;
    padding-left: 20px;
}

.red-flags li {
    font-size: 16px;
    color: #E53935;
}

/* Error messages */
.error {
    margin-top: 15px;
    color: #E53935;
    font-weight: bold;
    text-align: center;
}

/* Trust rating styling */
.job-analysis-container h4.trust-rating {
    font-size: 24px;
    color: #4A4A8C;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
}

.job-analysis-container .similar-job-btn {
    display: inline-block;
    margin-top: 10px;
    background-color: #0056b3;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
}

.job-analysis-container .similar-job-btn:hover {
    background-color: #35356e;
}

/* Steps container */
.steps-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
    text-align: center;
    margin-bottom: 100px;
}

.step {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20%;
    font-family: Arial, sans-serif;
}

.step i {
    font-size: 40px;
    color: #0056b3;
    margin-bottom: 10px;
}

.step p {
    font-size: 16px;
    color: #333;
    font-weight: bold;
}

/* General styles */
.disabled {
    background-color: rgb(145 145 145);
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.6;
}

/* Disabled file upload label */
.file-upload .disabled {
    background-color: rgb(145 145 145);
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.6;
}

/* Disabled text area */
textarea.disabled {
    background-color: rgb(218 218 218);
    cursor: not-allowed;
    opacity: 0.6;
}

/* Responsive design */

/* For tablets */
@media (width <= 768px) {
    .job-analysis-container {
        width: 95%;
        margin: 100px 0 auto;
        background-size: 100%;
        padding: 15px;
    }

    textarea {
        width: 80%;
    }

    button {
        font-size: 16px;
        padding: 8px 16px;
    }

    .steps-container {
        flex-direction: column;
        margin-top: 30px;
    }

    .step {
        width: 80%;
        margin-bottom: 20px;
    }

    .input-section {
        flex-direction: column; /* Stack textarea and upload button vertically */
    }

    textarea {
        margin-right: 0;
        margin-bottom: 15px; /* Add space between textarea and button */
    }
}

/* For mobile phones */
@media (width <= 480px) {
    .job-analysis-container {
        width: 100%;
        margin: 50px 0 auto;
        background-size: cover;
        padding: 10px;
    }

    textarea {
        width: 100%;
    }

    button {
        width: 100%;
        font-size: 14px;
        padding: 10px;
    }

    .input-section {
        flex-direction: column;
    }

    .file-upload label {
        width: 100%;
        text-align: center;
    }

    .steps-container {
        flex-direction: column;
    }

    .step {
        width: 100%;
        margin-bottom: 20px;
    }

    /* Ribbon Styles */
/* Intro Text Section */
.intro-text-header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f9f9f9;  /* Light background */
    padding: 20px;
    border-radius: 10px;
    margin: 0; /* Remove default margin to fit screen */
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    width: 100vw; /* Set width to cover the full viewport width */
    height: 100vh; /* Set height to cover the full viewport height */
    overflow: hidden; /* Prevents scrolling if content is too large */
}

.intro-text-content {
    width: 60%;  /* Take up most of the space */
}

.intro-text-content h1 {
    font-size: 28px;
    color: #2e3f52;
    margin-bottom: 10px;
}

.fade-in-text {
    font-size: 16px;
    color: #4d4f52;
    margin-bottom: 10px;
}

.explore-button {
    display: inline-block;
    background-color: #0056b3;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
    margin-top: 10px;
}

.explore-button:hover {
    background-color: #35356e;
}

.intro-video-container {
    width: 35%;  /* Adjust width based on available space */
}

.intro-video {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}

/* Ribbon Section */
.ribbon-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
}

.ribbon {
    position: relative;
    background-color: #0056b3;
    color: white;
    padding: 10px 20px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}

.ribbon i {
    margin-right: 10px; /* Space between icon and text */
    font-size: 20px;  /* Adjust icon size */
}

.ribbon::before, .ribbon::after {
    content: '';
    position: absolute;
    border-style: solid;
    height: 0;
    width: 0;
    top: 50%;
    transform: translateY(-50%);
}

.ribbon::before {
    left: -15px;
    border-width: 10px 15px 10px 0;
    border-color: transparent #0056b3 transparent transparent;
}

.ribbon::after {
    right: -15px;
    border-width: 10px 0 10px 15px;
    border-color: transparent transparent transparent #0056b3;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .intro-text-header-section {
        flex-direction: column;
        text-align: center;
    }

    .intro-text-content, .intro-video-container {
        width: 100%;  /* Full width on mobile */
    }

    .intro-video {
        margin-top: 20px;
    }
}
}

.job-analysis-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /*background-color: #181818;*/
    color: white;
}

.logo-container {
    margin-bottom: 20px;
    margin-top: 150px;
    width: 250px;
    height: 250px;
    display: flex;
    justify-content: center; /* Center the logo horizontally */
    align-items: center;     /* Center the logo vertically */
    border-radius: 50%;
    /*box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);*/
    overflow: hidden;        /* Ensures the image doesn't overflow the circle */
}

.analysis-logo {
    width: 100%;             /* Ensure the image takes full width */
    height: 100%;            /* Ensure the image takes full height */
    object-fit: cover;       /* Cover the container with the image without distortion */
    border-radius: 50%;      /* Ensure the image itself is circular */
}

.jd-header h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #0056b3;
}

.instruction-text {
    font-size: 1rem;
    color: #2c6fec;
    text-align: center;
    margin-bottom: 40px;
}

.user-options {
    display: flex;
    justify-content: space-around;
    width: 50%;
}

/* Ensure user-options container handles space between buttons evenly */
.user-options {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%; /* Ensure full width */
}

/* Adjust the spacing between the buttons */
.user-options {
    display: flex;
    justify-content: center; /* Center the buttons as a group */
    align-items: center;
    gap: 50px; /* Add a gap between the buttons */
}

.option-container {
    text-align: center;
}

.option-button-user {
    background-color: #333;
    color: white;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.2rem;
    width: 220px; /* Consistent fixed width */
    height: 120px; /* Consistent fixed height */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box; /* Include padding inside dimensions */
    padding: 0;
    margin: 0; /* Remove any margin */
}

.option-button-user i {
    margin-right: 10px;
    font-size: 1.5rem;
}

.option-button-user:hover {
    background-color: #444;
}

.file-input {
    display: none;
}

label.option-button-user,
button.option-button-user {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 120px;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

/* Remove any default margins or paddings that may affect button size */
/* button,
label {
    margin: 0;
    padding: 0;
    border: none;
    background: none;
} */


.error {
    color: red;
    margin-top: 20px;
}
.uploaded-description {
    margin-bottom: 10px;
}
.container {
    display: flex; /* This will make the children (left and right containers) appear side by side */
}

.left-container {
    width: 30%; /* Adjust width to take 48% of the space */
    padding-right: 10px;
    border-right: 1px solid #ddd; /* Optional: Add a border to distinguish */
    /* overflow-y: auto; Allow scrolling if content overflows */
}
.bot-icon-page {
    width: 250px;
    height: 250px;
    margin-right: 5px;
    border-radius: 50%;
    object-fit: cover;
}

/* Chatbot modal container */
/* Chatbot modal container */
.chatbot-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    z-index: 1000; /* Ensure it is on top of everything */
}

.right-container {
    width: 70%;
    flex: 1; /* Allow the right container to take equal or more space */
    padding-left: 10px;
    max-height: 80vh; /* Prevent overflow */
    overflow-y: auto; /* Scroll if content is too long */
}

/* Chatbot content window */
.chatbot-content {
    background-color: #fff;
    width: 80%; /* Wide window */
    height: 70vh; /* Adjust the height to take 80% of the viewport height */
    /* max-height: 700px; 
    max-width: 1000px;  */
    /* padding: 30px; */
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Subtle shadow */
    position: relative; /* Needed for the close button positioning */
}

/* Close button for chatbot */
.close-chatbot {
    position: absolute;
    top: 10px;
    right: 20px;
    background: none;
    border: none;
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.close-chatbot:hover {
    color: #ff0000;
}
.uploaded-image {
    max-width: 100%;  /* Make the image responsive */
    height: 50%;     /* Maintain aspect ratio */
    border-radius: 5px; /* Optional: Add some border radius */
    /* margin-top: 10px; Optional: Space above the image */
}
/* Chatbot header */
.chatbot-header {
    background-color: #054c97;
    color: white;
    padding: 10px;
    border-radius: 10px 10px 0 0;
    font-size: 1.5rem;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}



/* Buttons inside the chatbot modal */
/* Ensure chatbot footer has a flexbox layout to align buttons horizontally */
.chatbot-footer {
    display: flex;
    justify-content: center;
    gap: 10px; /* Space between the buttons */
    padding-top: 20px;
}
.button-container {
    display: flex; /* Enables flexbox */
    justify-content: center; /* Horizontally centers the button */
    align-items: center; /* Vertically centers if needed */
    margin-top: 20px; /* Optional: Adds space above */
}

/* Button styling */
.submit-button, .start-over-button {
    background-color: #085bb5;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin: 5px;
}

.submit-button:hover, .start-over-button:hover {
    background-color: #02172d;
}

/* Ensure buttons are sized evenly */
.submit-button, .start-over-button {
    width: 120px; /* Adjust button width as needed */
}

.read-more-button {
    background-color: #fcfcfc00;
    color: rgb(26, 154, 186);
    /* padding: 3px 3px; */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    margin: 5px;
}

.read-more-button:hover{
    background-color: #fdfdfd00;
    color: rgb(26, 83, 156);
}


/* Ensure chatbot modal content is spaced properly */
/* .chatbot-body {
    padding: 20px 0;
} */

/* Loading spinner */
.loading {
    margin-top: 20px;
    font-size: 16px;
    color: #333;
    text-align: center;
}

/* Chatbot response bubble */
.chatbot-bubble {
    background-color: #e3dddd;
    color: #333333;
    padding: 10px;
    border-radius: 15px;
    margin: 10px 0;
    max-width: 50%;
    display: block;
    text-align: left;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2), inset 0 4px 6px rgba(255, 255, 255, 0.5); /* Outer shadow and inner glow */
    
}
.chatbot-bubble.bot {
    background-color: #d1f1d1; /* Different color for bot messages */
    margin-left: auto; /* Align to the right */
}
.user-bubble {
    background-color: #0d4683;
    color: white;
    padding: 10px;
    border-radius: 15px;
    margin: 10px 0;
    max-width: 30%;
    display: inline-block;
    text-align: right;
    align-self: flex-end;
}


/* Chat bubbles container */
.chat-container {
    display: flex; 
     flex-direction: column;
    /* max-height: 500px; */
    /* overflow-y: auto; */
    padding: 10px;
}

/* Ensure chatbot modal content is spaced properly */
.chatbot-body-job {
    padding-left: 10px;;
}
.chat-message.red-flag {
    background-color: #f8d7da; /* Light red background */
    color: #721c24; /* Darker red text */
    padding: 10px;
    border-radius: 5px;
    font-weight: bold; /* Make the red flags bold */
}
/* User option buttons styled as bubbles (for user's response) */
.user-option-button-container {
    display: flex;
    justify-content: flex-end; /* Align options to the right */
    margin: 10px 0;
}

.user-option-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 15px;
    cursor: pointer;
    margin-left: 10px;
    font-size: 14px;
}

.user-option-button:hover {
    background-color: #0252a7;
}

.tick {
    color: green; /* Ensure the tick is green */
    font-size: 24px; /* Adjust size if needed */
    font-weight: bold;
}
.new-icon {
    width: 10px; /* Adjust size as needed */
    height: auto; /* Maintain aspect ratio */
    margin-left: auto;
    margin-right: 0px;
    align-self: right;
}

.dinosaur-icon {
    width: 40px; /* Adjust size as needed */
    height: auto; /* Maintain aspect ratio */
    margin-left: auto;
    margin-right: 5px;
    animation: spin 4s infinite linear; /* Spin animation */
    transform: perspective(100px) rotateY(0deg); /* 3D effect */
}

@keyframes spin {
    0% {
        transform: perspective(100px) rotateY(0deg); /* Start position */
    }
    50% {
        transform: perspective(100px) rotateY(360deg); /* Complete rotation */
    }
    100% {
        transform: perspective(100px) rotateY(720deg); /* Spin twice for a better effect */
    }
}

.chatbot-body-job {
    flex-direction: row;
    display: flex; /* This will arrange the containers side by side */
    justify-content: space-between; /* Space between left and right containers */
    gap: 20px; /* Optional: add space between the containers */
    max-height: 400px; 
    /* max-width: 80%; */
    /* overflow-y: auto; */
}
/* .chatbot-body {
    /* width: 48%; */
    /* display: flex; */
    /* flex-direction: column; Arrange children vertically */
    /* align-items: center; 
    max-height: 400px; 
    overflow-y: auto;  */
/* } */ 
.happy-bubble {
    background-color: #1bd659; /* Light green background for happy messages */
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2), inset 0 4px 6px rgba(255, 255, 255, 0.5); /* Outer shadow and inner glow */
    border-radius: 8px; /* Rounded corners */
    padding: 10px; /* Padding for better spacing */
}

.sad-bubble {
    background: linear-gradient(to bottom right, #f36464, #f09a9a); /* Subtle gradient for depth */
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2), inset 0 4px 6px rgba(255, 255, 255, 0.5); /* Outer shadow and inner glow */
    border-radius: 8px; /* Rounded corners */
    padding: 10px; /* Padding for better spacing */
    position: relative;
}

.neutral-bubble {
    background-color: #ea9029; /* Neutral gray background for default messages */
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2), inset 0 4px 6px rgba(255, 255, 255, 0.5); /* Shadow effect */
    border-radius: 8px; /* Rounded corners */
    padding: 10px; /* Padding for better spacing */
}


.text-header-container {
    display: flex !important;
    flex-grow: 1 !important; /* Allow it to take up available space */
    justify-content: center !important; /* Center the text */
    text-align: center !important;
    margin-left: 50px !important;
}

.text-header {
    flex-grow: 1 !important;
    color: white;
    text-align: center !important; /* This can be kept for older browsers */
}


.submit-button {
    margin-top: 10px; /* Space above the button */
    /* background-color: #007BFF; Blue background */
    color: white; /* White text */
    padding: 10px 20px; /* Add some padding */
    border: none; /* Remove borders */
    border-radius: 5px; /* Round corners */
    cursor: pointer; /* Pointer cursor */
    text-align: center; /* Center text inside the button */
}

.processing-messages {
    margin-bottom: 30px;
    margin-top: 30px; /* Space above the messages */
    text-align: center; /* Center the messages text */
}

.processing-message {
    display: flex;
    justify-content: center; /* Center spinner and text */
    align-items: center; /* Align items vertically */
    margin-bottom: 5px;
    font-size: 12px; /* Adjusted size for better visibility */
}

.spinner {
    margin-right: 5px; /* Space between spinner and text */
    
}
