/* FlipCardList.css */
.card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); 
    gap: 20px; 
    padding: 20px; 
    justify-items: center; 
  }
  
  
  @media (min-width: 1200px) {
    .card-grid {
      grid-template-columns: repeat(5, 1fr); 
    }
  }
  
  .flip-card {
    width: 100%; 
    max-width: 300px; 
    height: 350px; 
    perspective: 1000px; 
    transition: transform 0.3s ease; 
  }
  
  
  .flip-card:hover {
    transform: scale(1.1); 
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s ease;
    transform-style: preserve-3d;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); 
    border-radius: 10px; 
  }
  
  .flip-card.flipped .flip-card-inner {
    transform: rotateY(180deg); 
  }
  
  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px; 
    padding: 20px;
    border-radius: 10px; 
    box-sizing: border-box;
  }
  
  .flip-card-front {
    background-color: #00438a !important; 
    color: white;
  }
  
  .flip-card-back {
    background-color: #daf0ff;   
    color: white;
    transform: rotateY(180deg); 
  }
  
 
  @media (max-width: 900px) {
    .flip-card {
      height: 300px; 
    }
  }
  
  @media (max-width: 600px) {
    .flip-card {
      height: 250px; 
    }
  
    .flip-card-front,
    .flip-card-back {
      font-size: 16px; 
    }
  }
  