.contact-mode-container {
  width: 90%;
  overflow: hidden;
  text-align: center;
  padding: 30px;
  background-color: #f0f4f8;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  margin: 30px auto;
  min-width: 400px; /* Adjust this value as needed */
}

.contact-mode-container h2 {
  font-size: 20px !important;
  font-weight: bold;
  color: #333;
  margin-bottom: 25px;
}

.contact-mode-grid {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 20px;
  margin-top: 20px;
}

.contact-mode-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 15px; /* Increased border-radius for curvy edges */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
  text-align: center;
  transition: transform 0.3s ease;
}

.contact-mode-box:hover {
  transform: translateY(-5px);
}

.icon {
  font-size: 36px;
  margin-bottom: 10px;
}

.mode-name {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 5px;
}

.count {
  font-size: 14px;
  color: #666;
}

.subtitle {
  font-size: 16px;
  color: #666;
  margin-bottom: 15px;
}