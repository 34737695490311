.summary-container {
  width: 90%;
  overflow: hidden;
  text-align: center;
  padding: 30px;
  background-color: #f0f4f8;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  margin: 30px auto;
}

.summary-container h2 {
  font-size: 20px;
  font-weight: bold;
  color: #021930;
  margin-bottom: 25px;
}

.summary-entry {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
}

.summary-entry:hover {
  transform: translateY(-5px);
}

.gender-icon {
  font-size: 30px;
  margin-bottom: 10px;
}

.gender-icon.male { color: #3498db; }
.gender-icon.female { color: #e74c3c; }
.gender-icon.other { color: #9b59b6; }

.summary-entry h3 {
  font-size: 20px;
  color: #34495e;
  margin-bottom: 10px;
}

.report-count {
  font-size: 18px;
  color: #7f8c8d;
  margin-bottom: 5px;
}

.amount-lost {
  font-size: 16px;
  color: #2c3e50;
  margin-bottom: 15px;
}

.amount-lost strong {
  color: #d56d61;
}

.catchy-message {
  font-style: italic;
  color: #16a085;
  font-size: 16px;
}

.empowerment-message {
  margin-top: 25px;
  font-size: 18px;
  color: #020a0e;
}