.quiz-container {
  text-align: center;
  max-width: 50%;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.options-container {
  margin-top: 20px;
}

.option {
  margin-bottom: 10px;
}

.option-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  text-align: left;
  transition: background-color 0.3s ease;
}

.option-button:hover {
  background-color: #0056b3;
}

.next-question-button {
  padding: 14px 28px;
  font-size: 16px;
  color: white;
  background: linear-gradient(135deg, #4facfe, #00f2fe);
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  width: 160px; 
  height: 50px; 
  margin-top: 20px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.results-container {
  background-color: #f7f8fa;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 0 auto;
  font-family: 'Helvetica Neue', sans-serif;
}

h2 {
  font-size: 2.5rem;
  color: #333;
  font-weight: bold;
}

h3 {
  font-size: 1.75rem;
  color: #4facfe;
  margin-top: 20px;
}

.category-performance ul, .insights-feedback ul {
  list-style-type: none;
  padding-left: 0;
  margin-top: 20px;
}

li {
  background-color: #fff;
  padding: 15px 20px;
  margin-bottom: 15px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  font-size: 1rem;
  color: #555;
  display: flex;
  align-items: center;
}

li::before {
  content: '✔️ ';
  margin-right: 10px;
  color: #00c853;
  font-size: 1.2rem;
}

.score-header {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 20px;
}

.category-performance, .insights-feedback {
  margin-top: 30px;
}

@media (max-width: 600px) {
  .quiz-container {
    max-width: 100%;
    padding: 10px;
  }

  .results-container {
    padding: 20px;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  li {
    font-size: 0.9rem;
  }
}


/* Progress bar styling */
.progress-bar-container {
  margin: 20px 0;
  text-align: center;
}

.progress-bar {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 25px;
  height: 20px;
  overflow: hidden;
  margin-bottom: 10px;
}

.progress-bar-fill {
  background-color: #4facfe;
  height: 100%;
  transition: width 0.4s ease;
}

.progress-bar-container p {
  font-size: 1rem;
  color: #555;
  margin-top: 5px;
}


.progress-bar-container p {
  font-size: 1rem;
  color: #555;
  margin-top: 5px;
}

.progress-dots-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.progress-dot {
  width: 12px;
  height: 12px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: #e0e0e0;
  transition: background-color 0.3s ease;
}

.progress-dot.active {
  background-color: #4facfe;
}
