/* src/components/FlipCard.css */

.flip-card {
  width: 600px;
  height: 500px;
  perspective: 1500px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  transform-style: preserve-3d;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.flip-card.flipped .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: white;
  border-radius: 20px;
  padding: 20px;
  box-sizing: border-box;
}

/* Updated front with solid color */
.flip-card-front {
  background-color: #00438a; /* Solid dark blue */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  color: white;
  border-radius: 10px;
  box-sizing: border-box;
}

/* Back remains as a gradient */
.flip-card-back {
  transform: rotateY(180deg);
  color: black !important;
}

.click-info {
  font-size: 14px;
  margin-top: 20px;
  color: rgba(255, 255, 255, 0.8);
}

.custom-heading {
  font-size: 20px; 
  font-weight: bold; 
  margin: 10px 0; 
  line-height: 1.2; /* 调整行高 */
}
