/* General Styles */
.page-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9fbfd; 
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    margin-top: 100px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); 
}

/* Add a gradient to header background for a modern look */
.header-section {
    padding: 40px;
    background: linear-gradient(135deg, #ffffff, #eaf4fc);
    border-bottom: 1px solid #e0e0e0;
    text-align: left;
    border-radius: 12px 12px 0 0; 
}

.header-section h1 {
    font-size: 36px; 
    color: #094b8a;
    font-weight: 700; 
}

.header-section p {
    font-size: 18px;
    color: #666;
    margin: 20px 0;
    line-height: 1.8; 
}

.start-button {
    background-color: #0056b3; 
    color: white;
    padding: 14px 28px;
    font-size: 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12); 
    margin-top: 25px;
}

.start-button:hover {
    background-color: #003f8b; 
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); 
}

.button-section2 {
    display: flex;
    justify-content: space-between; 
    padding: 20px;
    margin-top: 20px;
}

.action-button {
    background-color: #1e90ff;
    color: white;
    padding: 16px 32px;
    font-size: 18px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    width: 30%;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.action-button:hover {
    background-color: #004080;
    box-shadow: 0 6px 14px rgba(0, 0, 0, 0.18);
    transform: translateY(-2px);
}

.content-section {
    padding: 30px;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    text-align: left;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.08); 
    margin-top: 30px;
    transition: box-shadow 0.3s;
}

.content-section:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12);
}

.content-item {
    margin-bottom: 20px;
    color: #444;
    font-size: 17px;
    line-height: 1.8;
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 15px;
    transition: color 0.3s ease;
}

.content-item strong {
    display: block;
    font-weight: 700;
    color: #094b8a;
    margin-bottom: 5px;
    cursor: pointer;
    font-size: 22px;
    transition: color 0.3s;
}

.content-item:hover strong {
    color: #0056b3;
}

.content-item p {
    margin-top: 8px;
    color: #666;
    font-size: 16px;
}

.content-item a {
    color: #1e90ff;
    text-decoration: none;
    font-weight: 500;
}

.content-item a:hover {
    text-decoration: underline;
}

/* Use an icon for dropdown visibility */
.content-item strong::after {
    content: ' ▼';
    font-size: 16px;
    color: #094b8a;
    margin-left: 10px;
    transition: transform 0.3s;
}

.content-item.active strong::after {
    content: ' ▲';
    transform: rotate(180deg);
}

/* Improve link styles */
.content-item a {
    display: flex;
    align-items: center;
    color: #007bff;
}

.content-item a:hover {
    color: #0056b3;
}

/* Mobile Styles */
@media (max-width: 600px) {
    .page-container {
        padding: 10px;
        margin-top: 50px;
    }

    .header-section {
        padding: 20px;
        text-align: center;
    }

    .header-section h1 {
        font-size: 28px;
    }

    .header-section p {
        font-size: 16px;
    }

    .start-button {
        font-size: 18px;
        padding: 10px 20px;
    }

    .button-section2 {
        flex-direction: column;
        align-items: center;
    }

    .action-button {
        width: 80%;
        margin-bottom: 15px;
    }

    .content-item strong {
        font-size: 18px;
    }

    .content-item p {
        font-size: 14px;
    }
}

/* Tablet Styles */
@media (min-width: 600px) and (max-width: 1024px) {
    .page-container {
        max-width: 90%;
    }

    .header-section {
        padding: 30px;
    }

    .button-section2 {
        flex-direction: row;
        flex-wrap: wrap;
    }

    .action-button {
        width: 45%;
        margin-bottom: 10px;
    }

    .content-item strong {
        font-size: 20px;
    }

    .content-item p {
        font-size: 16px;
    }
}