/* Navbar styles */
.navbar-container {
  position: relative;
  width: 100%;
}

.navbar {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background: white;
  z-index: 1050;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow for styling */
}

.navbar-logo {
  display: flex;
  align-items: center;
}

.navbar-logo .logo {
  height: 50px;
  margin-right: 20px;
}

.navbar h1 {
  font-size: 1.5rem;
  color: #123456;
}

.navbar-links {
  display: flex;
  align-items: center; /* Ensure vertical alignment of all items */
  gap: 5px;
  font-size: 16px;
}

.navbar-links a, .dropdown-toggle {
  text-decoration: none;
  color: black; /* Default black text */
  font-weight: 600;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 16px;
  margin-top: 0px;
  background-color: white; /* Ensure background is white */
  transition: background-color 0.3s ease, color 0.3s ease;
  font-family: Arial, sans-serif;  /* Inherit font from parent */
}

/* Dropdown Toggle */
.dropdown-toggle {
  /* padding: 0px; */
  display: flex; /* Keep it aligned with other links */
  align-items: center; /* Align text vertically */
}

/* Default and Hover Styles for Links */
.navbar-links a:hover,
.dropdown-toggle:hover {
  background-color: #1a1a89;
  color: white;
}

.navbar-title {
  text-decoration: none; /* Remove underline from the link */
}

.navbar-title h1 {
  margin: 0;
  font-size: 1.5rem;
}

.navbar-title:hover {
  text-decoration: none; /* Ensures underline doesn't appear on hover */
}

/* Hide the toggle menu button by default (for desktop views) */
.menu-toggle {
  display: none;
}

/* Dropdown Styling */
.dropdown {
  position: relative;
  display: inline-block;
  font-family: Arial, sans-serif; 
  /* width: 180px; */
  /* padding: 10px 20px; */
  /* font-size: 14px; */
  /* border-radius: 20px; */
}

.dropdown-menu {
  display: none; /* Hide dropdown by default */
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  padding: 10px;
  flex-direction: column;
  gap: 10px;
  top: 30px;
  transform: translateX(-50%);
  z-index: 999;
  font-size: 14px;
  font-family: Arial, sans-serif;  /* Inherit font from parent */
  font-weight: 200; /* Same font weight as navbar items */
}

/* Show dropdown on hover */
.dropdown:hover .dropdown-menu {
  display: flex;
}

.dropdown-menu a {
  padding: 5px 10px;
  border-radius: 5px;
  color: black; /* Default text color black */
  background-color: white; /* Default background white */
  font-family: Arial, sans-serif;  /* Inherit font from parent */
  font-weight: 200; /* Same font weight */
  width: 200px;
}

.dropdown-menu a:hover {
  background-color: #1a1a89;
  color: white;
}

/* Mobile styles */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    text-align: center;
  }

  .navbar-links {
    display: none; /* Hidden by default */
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .navbar-links.open {
    display: flex; /* Show when open */
  }

  .navbar-links a, .dropdown-toggle {
    padding: 10px;
    font-size: 14px;
    /* font-size: 1rem; */
  }

  /* Show the toggle button only on mobile devices */
  .menu-toggle {
    display: block;
    font-size: 1.5rem;
    background: none;
    border: none;
    cursor: pointer;
    color: #333;
  }

  .dropdown-menu {
    position: static;
    box-shadow: none;
  }
}

/* Smaller screen adjustments */
@media (max-width: 480px) {
  .navbar h1 {
    font-size: 1.2rem;
  }

  .navbar-logo .logo {
    height: 40px;
  }
}
