/* Wrapper for flip cards */
.trivia-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

@media (max-width: 600px) {
  .trivia-container {
    flex-direction: column;
    padding: 10px;
  }

  .sidebar-button {
    width: 100%;
    margin: 10px 0;
  }

  .header-section {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .trivia-container {
    padding: 20px;
  }

  .sidebar-button {
    width: 80%;
  }
}

.sidebar {
  display: none; /* Hide the sidebar since buttons are moved */
  flex-direction: column;
  margin-right: 20px;
}

.sidebar-button {
  padding: 10px 20px;
  font-size: 16px;
  margin: 0 10px; /* Adjust margin for better spacing */
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sidebar-button.active {
  background-color: #0056b3;
}

.sidebar-button:hover {
  background-color: #0056b3;
}

.content-area {
  flex-grow: 1;
  padding: 20px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  justify-content: center;
  align-items: center;
}

.trivia-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.trivia-card {
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  padding: 40px 30px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 600px;
  text-align: center;
  justify-content: center; 
  align-items: center;
  margin: 20px auto;
}

.trivia-card h2 {
  font-size: 26px;
  color: #34495e;
  margin-bottom: 20px;
}

.button-group {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.prev-button,
.next-button {
  padding: 14px 28px;
  font-size: 16px;
  color: white;
  margin-top: 20px;
  background: linear-gradient(45deg, #1d8cf8, #e2e3e7);
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 50px;
  text-align: center;
}

.prev-button:hover,
.next-button:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.header-section {
  margin-bottom: 20px;
  padding: 20px;
  /* background-color: #f8f9fa; */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding-left: 80px;
  padding-right: 40px;
  margin-left: auto;
}

.header-section h1 {
  font-size: 32px;
  /* margin-top: 100px; */
  color: #2c3e50;
  margin-bottom: 10px;
}

.header-section h3 {
  font-size: 18px;
  color: #34495e;
  margin-bottom: 20px;
  text-align: left;
}

.header-section h4 {
  font-size: 24px;
  color: #2c3e50;
  margin-bottom: 10px;
}

.header-section h5 {
  font-size: 18px;
  color: #34495e;
  margin-bottom: 20px;
  text-align: left;
}

.header-section h6 {
  font-size: 32px;
  color: #2c3e50;
  margin-bottom: 10px;
}

.header-section h7 {
  font-size: 18px;
  color: #34495e;
  margin-bottom: 20px;
  text-align: left;
}

/* Wrapper for flip cards */
.trivia-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.flip-card-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
}

.flip-card {
  background-color: transparent;
  width: 300px;
  height: 400px;
  perspective: 1000px;
  transition: transform 0.3s ease;
}

.flip-card:hover {
  transform: scale(1.1); /* Enlarge on hover */
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transform-style: preserve-3d;
  transition: transform 0.8s;
}

.flip-card.flipped .flip-card-inner {
  transform: rotateY(180deg); /* Flip only on click */
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
}

.flip-card-front {
  background-color: #ffffff;
}

.flip-card-back {
  transform: rotateY(180deg);
}

.flip-card h3 {
  font-size: 18px;
  color: #34495e;
  margin-bottom: 10px;
}

.flip-card p {
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 20px;
}

.flip-card button {
  padding: 10px 20px;
  font-size: 14px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.flip-card button:hover {
  background-color: #0056b3;
}

/* Responsive design for smaller screens */
@media (max-width: 1200px) {
  .flip-card-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 800px) {
  .flip-card-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .flip-card-grid {
    grid-template-columns: 1fr;
  }
}


.learn-more {
  margin-top: 20px;
  text-align: center;
}

.learn-more p {
  color: #ffffff;
  font-size: 16px;
  margin: 0;
  padding: 10px;
}


/* Job description header section (Full width) */
.job-description-header-section {
  width: 100%; 
  background-color: #fff; 
  padding: 20px 0;
  margin: 0; 
  display: flex;
  justify-content: center; 
  align-items: center;
}

/* Job analysis container */
.job-analysis-container {
  max-width: 1300px; 
  width: 90%;
  margin: 30px 0 auto;
  padding: 20px;
  background-size: 90%;
  background-blend-mode: normal;
  border-radius: 10px;
  /*box-shadow: 0 10px 16px rgb(0 0 0 / 10%);  */
  font-family: Arial, sans-serif; 
  text-align: left;
}

.job-analysis-container2 {
  max-width: 1300px; 
  width: 90%;
  margin: 300px 0 auto;
  padding: 20px;
  background-size: 90%;
  background-blend-mode: normal;
  border-radius: 10px;
  box-shadow: 0 10px 16px rgb(0 0 0 / 10%);
  font-family: Arial, sans-serif; 
  text-align: left;
}

.job-analysis-header {
  background-color: #6c8cb298;
  padding: 20px;
  border-radius: 10px; 
  margin-bottom: 45px;
}

h2 {
  font-size: 40px;
  font-weight: bold;
  color: #2e3f52; 
  text-align: center;
  margin-bottom: 20px;
}

.job-analysis-header-h4 {
  font-size: 15px;
  font-weight: bold;
  color: #4d4f52; 
  text-align: center;
  margin-bottom: 20px;
}

.button-section {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.toggle-button {
  padding: 10px 20px;
  font-size: 16px;
  margin: 0 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-button.active {
  background-color: #0056b3;
}

.toggle-button:hover {
  background-color: #0056b3;
}

.main-content {
  display: flex;
  justify-content: center;
}

.description-section {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
  font-size: 16px;
  color: #34495e;
}

.description-section p {
  margin: 0 auto;
  max-width: 700px;
  line-height: 1.5;
}

.description-section strong {
  font-size: 18px;
  color: #2c3e50;
}


.white-container {
  background-color: #ffffff; 
  border-radius: 12px; 
  padding: 70px 60px; 
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1); 
  margin: 40px auto; 
  width: 95%; 
  max-width: 1600px; 
}

.flip-card-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); 
  gap: 40px; 
}


@media (max-width: 1200px) {
  .flip-card-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 30px; 
  }
}

@media (max-width: 800px) {
  .flip-card-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px; 
  }
}

@media (max-width: 600px) {
  .flip-card-grid {
    grid-template-columns: 1fr; 
    gap: 20px;
  }
}
