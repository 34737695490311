/* General Styles */
body {
  background-color: #f0f0f0; /* Light gray background for the whole page */
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  
}
.intro-video-container {
  max-width: 35%; /* Limit the width to half of the section */
  /* display: flex; */
  flex:1;
  justify-content: center; /* Center the video horizontally */
  position: relative;
  z-index: 1; /* Lower than the filter section */
  max-width: 100%; /* Ensure it doesn't exceed the container */
}

.intro-video {
  width: 100%;
  height: 100%; /* Full height of the container */
  object-fit: cover; /* Cover the container, maintaining aspect ratio */
  border-radius: 15px; /* Rounded corners for the video */
  
}
.past-scam-major-header-section {
  width: 100%; 
  background-color: #fff; 
  padding: 20px 0;
  margin: 0; 
  display: flex;
  justify-content: center; 
  align-items: center;
}
.past-scam-header-section {
  max-width: 1300px; 
    width: 90%;
    margin:  0 auto;
    padding: 20px;
    background-size: 90%;
    background-blend-mode: normal;
    border-radius: 10px;
    /*box-shadow: 0 10px 16px rgb(0 0 0 / 10%);  */
    font-family: Arial, sans-serif; 
    justify-content: center;
    align-items: center;
}
/* Enhanced Styles for Intro Text Header Section */
.intro-text-header-section {
  margin-top: 150px;
  background: white; /* Background color */
  color: #12388a; /* Text color */
  padding: 60px 20px; /* Add padding for a spacious layout */
  box-shadow: 0 8px 16px rgba(200, 198, 198, 0.673); /* Subtle shadow for depth */
  position: relative;
  display: flex;
  flex-direction: row; /* Align text and video horizontally */
  align-items: center; /* Center content vertically */
  border-radius: 20px;
  height: auto; /* Adjust the height dynamically based on content */
  overflow: hidden;
  width: 100%; /* Full width */
  box-sizing: border-box; /* Include padding in width calculation */
  position: relative; /* Ensures z-index works */
  z-index: 1; /* Ensure it's behind the clickable elements */
}
.map-view-header-section {
  width: 100%; 
  background-color: #fff; 
  padding: 20px 0;
  margin: 0; 
  display: flex;
  justify-content: center; 
  align-items: center;
}

.intro-text-header-section::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 100%);
  /* background: rgba(255, 255, 255, 0.1); Light overlay for added depth */
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: 0; /* Behind the text content */
}

.intro-text-content {
 
  position: relative;
  flex:1;
  z-index: 1; /* Above the overlay */
}

.intro-text-content h1 {
  font-size: 28px;
  color: #0b407d;
  margin-bottom: 10px;
}

/* .intro-text-header-section h1 {
  font-size: 2rem;
  margin-bottom: 20px;
  animation: fadeInUp 1.5s ease-out forwards;
  color: #12388a;
} */

.intro-text-header-section p {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 15px;
  animation: fadeInUp 1.5s ease-out forwards;
}

.intro-text-header-section p:nth-child(2) {
  animation-delay: 1s;
}

.intro-text-header-section p:nth-child(3) {
  animation-delay: 2s;
}

.intro-text-header-section p:nth-child(4) {
  animation-delay: 3s;
}

.intro-text-header-section p:nth-child(5) {
  animation-delay: 4s;
}

.explore-button {
  display: inline-block;
  margin-top: 20px;
  padding: 15px 30px;
  background-color: #12388a; /* Bright color for call-to-action */
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s, transform 0.3s;
}

.explore-button:hover {
  background-color: #19a0be;
  transform: scale(1.05);
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Adjustments */
@media (max-width: 700px) {
  .intro-text-header-section {
    padding: 40px 15px; /* Reduce padding on smaller screens */
  }

  .intro-text-header-section h1 {
    font-size: 2rem; /* Adjust heading size for smaller screens */
  }

  .intro-text-header-section p {
    font-size: 1rem; /* Adjust paragraph size for smaller screens */
  }

  .explore-button {
    padding: 12px 24px; /* Adjust button size */
    font-size: 1rem;
  }
}


.intro-text p:last-child {
  margin-bottom: 0; /* Remove bottom margin for the last paragraph */
}

.fade-in-text {
  font-family: 'Poppins', sans-serif; /* Clean, modern font for paragraphs */
  opacity: 0; /* Initially invisible */
  font-size: 1.2rem;
  color: rgb(19, 12, 12); /* Use a contrasting color for readability */
  animation: fadeIn 1.5s ease-in-out forwards;
  margin: 10px 0;
}

.fade-in-text:nth-child(2) { /* Delay for the first paragraph */
  animation-delay: 1.5s;
}

.fade-in-text:nth-child(3) { /* Delay for the second paragraph */
  animation-delay: 3s;
}

.fade-in-text:nth-child(4) { /* Delay for the third paragraph */
  animation-delay: 4.5s;
}

.fade-in-text:nth-child(5) { /* Delay for the fourth paragraph */
  animation-delay: 6s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px); /* Slight upward motion */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* Return to normal position */
  }
}

.space-before {
  margin-top: 2rem; /* Adjust the value as needed */
}

.container {
  background-color: #ffffff; /* White background for the container */
  border-radius: 8px; /* Rounded corners for the container */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4); /* Subtle shadow for depth */
  padding: 20px; /* Space inside the container */
  /* Space around the container */
  margin-top: 200px;
  max-width: 1200px;
  margin-bottom: 100px;
  /* margin-top: 100px; */
}

.chart-section {
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  padding: 0 45px;
  width: 100%;
  gap: 40px;
}

.info.legend {
  background: white;
  padding: 6px 8px;
  font: 14px Arial, Helvetica, sans-serif;
  border-radius: 5px;
}

.selected-state-section,
.selected-state-info {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;
}

.info.legend i {
  width: 24px;
  height: 24px;
  float: left;
  margin-right: 8px;
  opacity: 0.7;
}

.info.legend .labels {
  margin: 0;
  padding: 0;
  list-style: none;
}
.map-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start; /* Align items to the start */
  justify-content: space-between; /* Space out items */
  width: 100%;
}

.map-container {
  margin-left: 15px; /* Add spacing from the map */
}


.map-container {
  height: 600px;
  width: 100%;
  
  background-color: white;
  display: flex;
    flex-direction: column;
    align-items: center;
}
.map-container h2 {
  font-size: 30px;
  font-weight: bold;
  color: #082a51; 
  text-align: center;
 
}
@media (max-width: 768px) {
  body {
    padding-top: 150px; /* Adjust this based on the height of the navbar in mobile view */
  }
  .map-container {
    display: flex;
    height: 400px; /* Adjust height for smaller screens */
  }
  .map-wrapper {
    flex-direction: column; /* Stack vertically on smaller screens */
    align-items: center; /* Center items */
  }
  .color-scale-bar-container{
    display: none;
  }
  .color-scale-bar {
    display:none;
  }
  .map-container  {
    overflow: auto; 
    margin-left: 0; /* Remove left margin */
    margin-top: 10px; /* Add top margin for spacing */
    width: 100%; /* Full width to ensure it doesn’t overlap */
  }
}
@media (max-width: 480px) {
  .map-container  {
    margin-top: 5px; /* Reduce top margin for very small screens */
  }

  .map-container .map-wrapper {
    padding: 0 10px; /* Add some padding for very small screens */
  }
}
.filter-section {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  padding: 15px;
  background-color: #052d89;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 10; /* Ensure the filter section is on top */
  transition: all 0.3s ease;
}

.filter-section:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.filter-group {
  margin: 0 20px;
}

.filter-group label {
  color: #ffffff;
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 14px;
}

.filter-group select {
  padding: 8px 12px;
  width: 180px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  transition: all 0.2s ease;
  font-size: 14px;
}

.filter-group select:hover {
  border-color: #007bff;
}

.filter-group select:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  border-color: #007bff;
}

.button-group {
  display: flex;
  align-items: center;
}

.button-group button {
  margin-left: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  background-color: #187acb;
  color: white;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 10px rgba(0, 123, 255, 0.2);
}

.button-group button:hover {
  background-color: #00a2ff;
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(0, 123, 255, 0.3);
}

.color-scale-bar {
  
  background: rgba(255, 255, 255, 0.8);
  padding: 8px;
  font-size: 14px;
  z-index:500;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.donut-chart-container {
  margin-top: 20px;
  width: 100%; 
  height: 400px;
}

.donut-chart-container h3 {
  text-align: center;
  margin-bottom: 10px;
}

.map-view-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chart-container {
  display: flex;
  align-items: center;
  position: relative;
}

.info-button {
  margin-left: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.info-button:hover {
  background-color: #0056b3;
}

.container {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slide-out {
  transform: translateX(-100%);
}

.slide-in {
  transform: translateX(0);
}

/* .chart-container {
  transform: translateX(100%);
} */

.chart-container.slide-in {
  transform: translateX(0);
}

.loading {
  text-align: center;
  font-size: 18px;
  color: #555;
}

.loading-animation {
  margin-top: 10px;
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.tooltip-container {
  padding: 10px;
  background-color: #eef;
  border-radius: 5px;
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  color: #333;
}

.cta-button {
  margin: 0 auto; /* Center the button horizontally */
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  background-color: #187acb;
  color: white;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 10px rgba(0, 123, 255, 0.2);
  display: block;
}

.cta-button:hover {
  background-color: #00a2ff;
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.info-panel {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .container {
    margin: 10px;
    padding: 10px;
  }
  
  .chart-section {
    padding: 0 20px;
    gap: 20px;
  }
  
  .filter-section {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .button-group {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .intro-text {
    padding-top: 50px;
    margin-top: 50px;
    font-size: 0.9rem;
  }
}

/* Responsive Adjustments for Mobile (max-width: 768px) */
@media (max-width: 500px) {
  /* Stack elements vertically */
  .intro-text-header-section {
    flex-direction: column;
    padding: 40px 20px;
    margin-top: 100px;
  }

  /* Full-width for video container */
  .intro-video-container {
    max-width: 100%;
    margin-bottom: 20px;
  }

  /* Center text content for mobile */
  .intro-text-content {
    text-align: center;
  }

  /* Responsive font sizes */
  .intro-text-content h1 {
    font-size: 1.8rem;
  }

  .intro-text-header-section p {
    font-size: 1rem;
  }

  .explore-button {
    font-size: 1rem;
    padding: 12px 24px;
  }

  /* Responsive adjustments for Past Scams Section */
  .past-scam-header-section {
    padding: 15px 10px;
    text-align: center;
  }

  .past-scam-header-section h1 {
    font-size: 1.8rem;
  }

  .past-scam-header-section p {
    font-size: 1rem;
  }

  /* Responsive adjustments for Map View Section */
  .map-view-header-section {
    padding: 15px 10px;
    text-align: center;
  }
  .map-container {
    height: 400px; /* Reduce map height for mobile */
    margin-top: 20px; /* Space between map and header */
  }

  /* Map View Header Section text adjustments */
  .map-view-header-section h1 {
    font-size: 1.6rem; /* Smaller text size for mobile */
  }

  /* Ensure header doesn't overlap the map */
  .map-view-header-section {
    z-index: 10; /* Make sure the header stays on top of the map */
    margin-bottom: 10px; /* Add margin below header */
  }

  /* Prevent header overlap by giving space for the map */
  .map-container {
    margin-top: 20px; /* Add top margin to avoid header overlap */
  }

  .map-view-header-section h1 {
    font-size: 1.7rem;
  }

  /* Adjust card width in rows for mobile */
  .card {
    width: 100%; /* Full width on mobile */
    margin-bottom: 20px; /* Add space between cards */
  }

  /* Feature cards hover animation for mobile */
  .feature-card:hover {
    transform: none; /* Remove hover effects for touch screens */
  }

  /* Mobile friendly stacked bar chart */
  .stacked-bar-chart {
    width: 100%;
    height: auto; /* Auto height for responsiveness */
  }

  /* Adjust filters placement on mobile */
  .filters-container {
    flex-direction: column;
    padding: 10px 20px;
    align-items: center;
  }

  .filter-item {
    margin-bottom: 10px;
    width: 100%;
  }

  /* Mobile adjustments for Stacked Bar Chart */
  .stacked-bar-chart-container {
    width: 100%;
    padding: 20px 0;
  }

  /* Full page intro text for mobile */
  .intro-text-header-section {
    height: auto; /* Remove fixed height for mobile */
  }

  /* Icons adjustments for mobile */
  .contact-mode-icon {
    width: 50px;
    height: 50px;
  }

  .contact-mode-icons {
    justify-content: space-around;
  }
}

.down-arrow {
  position: fixed;
  bottom: 30px; /* Adjust based on where you want the arrow to appear */
  left: 50%; /* Center the arrow horizontally */
  transform: translateX(-50%); /* Center the arrow */
  /*background-color: #1d72b8;  Blue background 
  color: #fff; 
  border: 2px solid #000; Black border */
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 24px; /* Adjust size of the arrow */
  border-radius: 8px; /* Make it a square with slightly rounded corners */
  z-index: 1000; /* Ensure it stays on top */
  animation: bounce 2s infinite; /* Add a bounce animation */
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}
