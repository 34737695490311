/* ScrollArrowButton.css */
.down-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 30px;
    right: 30px; /* Adjust the position as needed */
    cursor: pointer;
    z-index: 10000; /* Ensure it's on top of other elements */
    animation: bounce 2s infinite; /* Add the bounce animation */
    filter: drop-shadow(0 0 3px rgba(255, 255, 255, 0.8));
  }
  
  .arrow-image {
    width: 50px; /* Set the size of the arrow image */
    height: 50px;
  }
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }
  