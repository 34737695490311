.job-opportunities-page {
  font-family: 'Helvetica Neue', sans-serif;
  padding: 20px;
  padding-top: 150px;
  background: linear-gradient(135deg, #f0f4f8, #d9e2ec);
  min-height: 100vh;
}

.warning-message {
  margin-top: 20px;
  padding: 10px;
  background-color: #eabac7;
  border: 1px solid #e90a0a;
  border-radius: 5px;
}

.warning-message p {
  margin: 0;
  color: #120101 !important;
  font-size: 1rem !important;
}

.job-search {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.job-search input {
  padding: 12px;
  border: 1px solid #ced4da;
  border-radius: 30px;
  width: 250px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  transition: all 0.3s ease;
}

.job-search input:focus {
  border-color: #80bdff;
  box-shadow: 0 3px 6px rgba(0, 123, 255, 0.3);
}

.job-search button {
  padding: 12px 25px;
  background-color: #00438a;
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.job-search button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.content {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.job-list,
.job-details {
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.job-list {
  flex: 1;
  padding-right: 20px;
  max-width: 400px;
  margin-right: 20px;
}

.job-item {
  background-color: #f8f9fa;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid #dee2e6;
  transition: box-shadow 0.3s ease;
}

.job-item:hover {
  box-shadow: 0 4px 10px rgba(0, 123, 255, 0.2);
  border-color: #00438a;
}

.job-details {
  flex: 2;
}

.job-details h2 {
  margin-top: 0;
  font-size: 1.5rem;
  color: #00438a;
}

.job-item h3 {
  margin-top: 0;
  font-size: 1.5rem;
  color: #00438a;
}

.job-details p {
  margin: 10px 0;
  line-height: 1.6;
  text-align: left !important;
}

.job-details button {
  margin-top: 20px;
  padding: 12px 30px;
  background-color: #00438a;
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.job-details button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.job-opportunities-page h1 {
  text-align: center;
  font-size: 2.5rem;
  color: #343a40;
  margin-bottom: 20px;
  letter-spacing: 1px;
}

.job-opportunities-page p {
  text-align: center;
  font-size: 1.2rem;
  color: #0b0b0b;
  margin-bottom: 40px;
}


@media (max-width: 1024px) {
  .job-search input {
    width: 200px;
  }

  .content {
    flex-direction: column;
    align-items: center;
  }

  .job-list, .job-details {
    max-width: 90%;
    margin-bottom: 20px;
  }

  .job-details {
    flex: unset;
  }
}

@media (max-width: 768px) {
  .job-opportunities-page {
    padding: 10px;
  }

  .job-search {
    flex-direction: column;
    align-items: center;
  }

  .job-search input,
  .job-search button {
    width: 100%;
    margin-bottom: 10px;
  }

  .content {
    flex-direction: column;
  }

  .job-list,
  .job-details {
    width: 100%;
    padding: 15px;
  }

  .job-list {
    margin-right: 0;
  }
}


