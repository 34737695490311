/* Container for the whole section */
.chart-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align items */
  padding: 30px; /* Adjust padding to match other containers */
  background: #f0f4f8; /* Use the same background color as other containers */
  width: 90%; /* Keep consistent with the other containers */
  max-width: 1200px; /* Maintain the max width */
  margin: 30px auto; /* Center the container with margin */
  border-radius: 15px; /* Add rounded corners for a cohesive look */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
}

/* Explanation text section */
.explanation-text {

  text-align: center;
  margin-bottom: 20px; /* Spacing below the explanation text */
  max-width: 90%; /* Limits the width of the explanation text */
  padding: 10px; /* Padding around the text */
  background: #ffffff; /* White background for the text section */
  border-radius: 15px; /* Rounded corners for the background */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
}

.explanation-text h2 {
  font-size: 22px; /* Adjust font size for the heading */
  font-weight: bold; /* Bold text for the heading */
  color: #333; /* Dark color for the heading */
  margin-bottom: 8px; /* Spacing below the heading */
}

.explanation-text p {
  font-size: 14px; /* Font size for the paragraph */
  color: #666; /* Lighter color for the paragraph text */
  line-height: 1.5; /* Line height for better readability */
}

/* Chart container */
.chart {
  width: 100%; /* Full width for the chart container */
  height: 75vh; /* Use percentage height for responsiveness */
  max-width: 1000px; /* Maximum width for large screens */
}

/* Responsive design for mobile screens */
@media (max-width: 600px) {
  .explanation-text h2 {
    font-size: 18px; /* Adjust font size for smaller screens */
  }
  
  .explanation-text p {
    font-size: 12px; /* Adjust font size for smaller screens */
  }

  .chart {
    height: 60vh; /* Reduce chart height for smaller screens */
  }
}
