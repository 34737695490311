.next-step-button-container {
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    padding-bottom: 60px;
}

.next-step-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px; 
    color: #333; 
}

.custom-next-button {
    display: flex;
    align-items: center;
    padding: 12px 24px;
    font-size: 16px;
    color: white;
    background: linear-gradient(135deg, #007bff, #0056b3); 
    border: none;
    border-radius: 25px;
    cursor: pointer;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transition: background 0.3s ease, transform 0.3s ease;
}

.custom-next-button:hover {
    background: linear-gradient(135deg, #0056b3, #003d80);
    transform: translateY(-3px);
}

.custom-next-button:focus {
    outline: none;
}

.custom-next-button i, .custom-next-button svg {
    margin-left: 8px;
}
