/* Global styles */
body, html {
  margin: 0;
  padding: 0;
  /* height: 100%; */
  /* overflow-x: hidden; */
  font-family: 'Montserrat', sans-serif;
  background-color: #f5f7fa;
  height: auto; /* Allow body and html to expand */
 /* overflow-y: scroll;  Ensure vertical scroll is always available */
}

/* Hero Section */
.hero-background {
  display: flex;
  flex-direction: column;
  /* min-height: 100vh; */

  color: #fff;
}

.hero-section {
  display: flex;
  justify-content: center; /* Centers content horizontally */
  align-items: center;    /* Centers content vertically */
  height: 100vh;
  position: relative;
  
  overflow: hidden; /* Prevents overflow from pseudo-element */
}

.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  background-image: url('../../public/homepage.jpg'); /* Use the same image */
  background-size: cover;
  background-position: center top; /* Keeps the original position */
  background-color: rgba(0, 0, 0, 0.86); /* Darkens the image */
  filter: blur(2px); /* Apply the blur */
  z-index: 1; /* Ensure the blur is behind the content */
}

.hero-section .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Darkening effect */
  filter: blur(8px); /* Apply the blur */
  z-index: 1; /* Ensure the overlay is behind the content */
}

.hero-section .hero-content {
  z-index: 2;
  animation: fadeIn 1s ease-in-out;
  padding: 0 20px;
  text-align: center; /* Centers the text */
}

.hero-content h1 {
  font-size: 3rem; /* Adjust this size as needed */
  font-weight: bold;
  margin-bottom: 20px;
}

.hero-content p {
  font-size: 1.5rem; /* Adjust this size as needed */
  margin-bottom: 30px;
}

.get-started-btn {
  padding: 12px 30px;
  background: #57C4E5;
  border-radius: 30px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  font-size:  1rem;
  text-decoration: none;
  transition: all 0.3s ease;
  display: inline-block; /* Ensure the button is inline with the text */
  margin-top: 20px; /* Adjust this to move the button slightly below the text */
}

.get-started-btn:hover {
  background-color: #2d9cb5;
  transform: scale(1.05);
}

/* Services Section */
.services-section {
  padding: 100px 0;
  background-color: #fff;
}

.service-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
  position: relative;
  
  transform: translateY(30px); /* Start from below */
  transition: opacity 0.6s ease-in-out, transform 0.6s ease-in-out; /* Smooth transition */
}

.service-item.left {
  flex-direction: row;
}

.service-item.right {
  flex-direction: row-reverse;
}
/* 
.service-image {
  width: 100%;
  height: 300px;
  position: relative;
  overflow: hidden;
} */

.service-image img {
  width: 120%;
  height: auto;
  position: absolute;
  left: -10%;
  top: -10%;
  object-fit: cover;
}
.service-shape {
  width: 45%;
  height: 300px;
  position: relative;
  overflow: hidden;
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.service-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.service-shape:hover .service-image {
  transform: scale(1.1);
}
.service-content {
  width: 45%;
  padding: 0 5%;
  /* margin-left: 15px; */
}

.service-content h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
}

.service-content p {
  font-size: 1.1rem;
  line-height: 1.4; /* Less stretched text */
  color: #555;
  margin-bottom: 30px;
}

.service-link {
  display: inline-block;
  padding: 12px 25px;
  background-color: #57C4E5;
  color: #fff;
  text-decoration: none;
  border-radius: 30px;
  font-weight: bold;
  transition: all 0.3s ease;
  margin: 0 auto;
  text-align: center;
}

.service-link:hover {
  background-color: #2d9cb5;
}

/* Animation for service items */
/* @keyframes fadeInUp{ */
  /* from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.service-item.animate {
  animation: fadeInUp 0.6s ease-in-out forwards; /* `forwards` ensures the final state is retained */
/* } */ 

/* About Section */
.about-section {
  padding: 50px;
  background-color: #f5f7fa;
  text-align: center;
}

.section-title {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

.about-section p {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #555;
}

/* Empowerment Section */
.empowerment-section {
  padding: 50px;
  background-color: #fff;
  text-align: center;
}

.animate-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.animate-list li {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #333;
}

/* User Journey Section */
.user-journey {
  background-color: #ffffff;
  padding: 20px 20px;
  text-align: center;
}

.user-journey h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 30px;
}

.journey-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.journey-intro {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 30px;
}

.journey-row {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}

.journey-row::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #04171d;
  z-index: 0;
  width: 100%; 
}

.journey-step {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  width: calc(30% - 20px);
  max-width: 250px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  z-index: 1;
  text-decoration: none; 
  color: inherit; 
  display: flex;
  flex-direction: column;
  align-items: center;
}

.journey-step:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.step-icon {
  font-size: 3rem;
}

.journey-step h3 {
  font-size: 1.2rem;
  color: #001e8d;
}

.journey-step p {
  font-size: 0.9rem;
  color: #666;
}

/* Dot Styles */
.dot {
  width: 12px;
  height: 12px;
  background-color: #060f36;
  border-radius: 50%;
  margin: 0 15px;
  position: relative;
  z-index: 1;
}

/* Responsive Design */
@media (max-width: 768px) {
  .journey-row {
    flex-direction: column;
    gap: 20px;
  }
  .journey-step {
    width: calc(80% - 20px);
  }
  .dot {
    display: none;
  }
  .journey-row::before {
    display: none;
  }
}

.scroll-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  cursor: pointer;
  margin-top: 50px;
  color: #1d72b8; /* Change to desired color */
  animation: bounce 2s infinite;
  filter: drop-shadow(0 0 3px rgba(255, 255, 255, 0.8));
}

/* .scroll-arrow:hover {
  color: #155a8a; 
} */

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.hero-scroll-arrow {
  position: absolute; /* Make sure it's positioned relative to its parent */
  bottom: 20px; /* Place it near the bottom of the hero section */
  left: 50%; /* Center the arrow horizontally */
  transform: translateX(-50%); /* Center the arrow */
  font-size: 20px; /* Increase the size to make the arrow more prominent */
  cursor: pointer;
  color: #1d72b8; /* Set the arrow color */
  animation: bounce 2s infinite; /* Add a subtle bounce animation */
  z-index: 10; /* Ensure the arrow is above other elements */
  filter: drop-shadow(0 0 3px rgba(255, 255, 255, 0.8));
}

/* .hero-scroll-arrow:hover {
  color: #155a8a;
} */

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.arrow-image {
  width: 50px; /* Set your desired size for the arrow image */
  height: 50px;
}

.explore-link {
  color: white;
  text-decoration: underline;
  cursor: pointer;
  padding: 10px;
}

.explore-link:hover {
  color: #ccc; /* Change the color on hover for effect */
}

