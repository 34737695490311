body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
}

@media (max-width: 768px) {
  .container {
      width: 90%;
      padding: 0 15px;
  }
  .navbar {
      flex-direction: column;
      text-align: center;
  }
}


@media (max-width: 480px) {
  .container {
      width: 100%;
      padding: 0 10px;
  }
  .footer {
      padding: 10px;
  }
}

.app-container {
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  position: relative;
}

.navbar-container {
  width: 100%;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: background-color 0.3s ease;
}

.navbar.fixed {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.9); 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: slide-down 0.3s ease;
}


.get-started-btn {
  background-color: #0066cc;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1.2rem;
  margin-top: 20px;
}

.get-started-btn:hover {
  background-color: #005bb5;
}

.content-section {
  padding: 40px 20px;
  background-color: #f7f7f7;
}
