/* src/components/ScrollToTopButton.css */
.scroll-to-top-button {
    position: fixed;
    bottom: 30px; /* Distance from the bottom of the page */
    left: 30px; /* Position the button on the left side */
    width: 0px;
    height: 0px;
    /* background-color: #1d72b8;
    border: 2px solid #000; 
    border-radius: 8px; Small rounding for a square look */
    cursor: pointer;
    z-index: 10000; /* Ensure the button is above other elements */
    display: flex;
    align-items: center;
    justify-content: center; /* Center the image inside the square */
    transition: transform 0.3s ease;
}

.scroll-to-top-button:hover {
    /* background-color: #155a8a; Darker blue on hover */
    transform: scale(1.1); /* Slight scaling effect on hover */
}

.arrow-icon {
    width: 35px; /* Adjust the size of the image */
    height: 35px;
}

.scroll-to-top-button.hidden {
    opacity: 0;
    visibility: hidden; /* Hide the button */
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;
}

/* Keyframes for the bounce animation */
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-5px);
    }
}
